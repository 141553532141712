@charset "utf-8";
/* CSS Document */

/* 경철경철 CSS! */
.cursor {
    cursor: pointer;
}

#__react-alert__ {
    z-index: 100 !important;
}

/*G마켓산스*/
@font-face {
    font-family: "GmarketSansMedium";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "GmarketSansBold";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
        format("woff");
    font-weight: 700;
    font-style: normal;
}

/*에스코어드림*/
@font-face {
    font-family: "SCDream4";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "SCDream6";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff")
        format("woff");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "SCDream7";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff")
        format("woff");
    font-weight: 700;
    font-style: normal;
}

/* @font-face {
    font-family: "SCDream4";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SCDream4.otf") format("truetype");
}

@font-face {
    font-family: "SCDream6";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/SCDream6.otf") format("truetype");
}

@font-face {
    font-family: "SCDream7";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/SCDream7.otf") format("truetype");
} */

/*공통코드*/
body,
div,
p,
ul,
li,
ol,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
fieldset {
    margin: 0;
    padding: 0;
}
body {
    font-family: "SCDream4", sans-serif;
    font-size: 17px;
    line-height: 1em;
    color: #191919;
    letter-spacing: -0.5px;
}
img {
    border: none;
    vertical-align: top;
}
select,
input {
    vertical-align: top;
}
fieldset {
    border: none;
}
legend {
    display: none;
}
hr {
    border: none;
    clear: both;
}
ul,
li,
ol {
    list-style: none;
}
table {
    border-collapse: collapse;
}
a,
.gnb li div,
.lnb li div {
    text-decoration: none;
    color: #686868;
}

/*float 속성 해지 및 부모태그 높이값을 자동으로 인식시키기 위한 가상의 클래스를 생성*/
.clearboth {
}
/*가상의 클래스를 생성하여 다음과 같은 처리를 해준다*/
.clearboth:after {
    content: "";
    display: block;
    clear: both;
}
/*사용법:float을 적용할 형제들의 엄마태그에 가상의 클래스 .clearboth 적용*/
