@charset "utf-8";
/* CSS Document */

/********inner**********/
.inner {
    width: 1140px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 auto;
}

/*****nav*****/
.bfresh_under_line {
    background: url(../images/deco_underline.png) repeat-x bottom;
    font-family: "GmarketSansBold";
}

nav.mainnav {
    width: 100%;
    height: 56px;
    position: fixed;
    z-index: 10;
    line-height: 56px;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

nav.subnav {
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.nav_top {
    width: 100%;
    height: 56px;
    line-height: 56px;
}

.nav_bottom {
    width: 100%;
    height: 48px;
    line-height: 48px;
    border-top: 1px solid #e0e0e0;
    box-sizing: border-box;
}

.bfreshlogo {
    float: left;
}

.bfreshlogo img {
    margin-top: 10px;
}

.gnb {
    float: right;
}

.gnb li {
    float: left;
    margin-right: 30px;
    font-size: 16px;
}

.gnb li a:hover,
.gnb li div:hover {
    color: #191919;
}

.cursor.navOn {
    color: #00afec !important;
}

.lnb li {
    float: left;
    margin-right: 30px;
    font-size: 14px;
}

.lnb li a:hover,
.lnb li div:hover {
    color: #191919;
}

.lnbtit {
    font-weight: 600;
    color: #00b0ec;
}

/********메인이미지********/
.main_01 {
    padding-top: 56px;
    background: url(../images/mainimg_bottom_bg.png) no-repeat bottom -40% center;
}

.main_01_top {
    width: 100%;
    height: 604px;
    background: url(../images/mainimg_bg.png) no-repeat center;
}

.main_01_top_cont {
    height: 560px;
    background: url(../images/mainimg_truck.png) no-repeat right center;
    position: relative;
}

.main_01_top_cont img {
    position: absolute;
    top: 120px;
}

.main_01_bottom {
    width: 100%;
    height: 300px;
}

.QR {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}

.QR_txt {
    font-family: "GmarketSansMedium", sans-serif;
    font-size: 24px;
    line-height: 39px;
    color: #484848;
    margin-left: 30px;
}

.main_02 {
    width: 100%;
    background-color: #00afec;
    padding-top: 70px;
    padding-bottom: 60px;
    text-align: center;
}

.main_02_txt {
    font-family: "GmarketSansMedium", sans-serif;
    font-size: 29px;
    line-height: 45px;
    color: #ffffff;
    margin-top: 40px;
}

.main_03 {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
}

.companylogo {
    display: flex;
    justify-content: center;
}

.companylogo li {
    margin-right: 25px;
}

.companylogo li:last-child {
    margin-right: 0;
}

/*********컨텐츠*******/
.contents {
    padding-top: 105px;
}

.contents_02 {
    padding-top: 56px;
}

.section {
    margin-bottom: 110px;
}

.cont_top_img {
    height: 210px;
    background: url(../images/cont_top_bg.png) no-repeat center #00b0ec;
    line-height: 210px;
    margin-bottom: 50px;
}

.cont_top_img p {
    font-size: 36px;
    font-family: "SCDream6";
    color: #ffffff;
}

.cont_tit {
    font-size: 36px;
    text-align: center;
    font-family: "SCDream6";
    color: #484848;
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 40px;
}

.cont_txt {
    line-height: 35px;
    margin-bottom: 40px;
}

.bluebold {
    font-weight: 600;
    color: #00afec;
}

.bold {
    font-weight: bold;
}

.cont_box {
    margin-bottom: 60px;
}

.box {
    display: flex;
    margin-bottom: 30px;
}

.box > div {
    width: 555px;
    line-height: 35px;
}

.future_txt {
    background: url(../images/info_img_07.png) no-repeat right center;
}

.cont_txt_future {
    width: 706px;
    line-height: 35px;
}

.company_info {
    text-align: center;
    line-height: 35px;
}

.company_info img {
    margin-bottom: 20px;
}

.company_name {
    font-size: 24px;
    font-family: "SCDream6";
}

.company_info_box {
    padding: 20px 0;
    width: 700px;
    border-radius: 16px;
    background-color: #f6f6f6;
    margin: 20px auto 0;
}

/*********문의하기********/
.formlist ul {
    border-top: 1px solid #4e4e4e;
    margin-bottom: 40px;
}

.formlist ul li {
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 0;
    display: flex;
    align-items: center;
}

.formlist label {
    width: 240px;
    padding-left: 30px;
}

.inputbox {
    width: 700px;
    height: 40px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    padding-left: 10px;
    font-family: "SCDream4";
}

.message {
    width: 700px;
    height: 360px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    padding-left: 10px;
    font-family: "SCDream4";
}

.inquiry_tit {
    font-family: "SCDream6";
    font-size: 24px;
    color: #484848;
    margin-bottom: 20px;
}

.inquiry_txt {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    padding: 20px 30px;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
}

/*checkbox*/

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    box-shadow: none;
    background: url(../images/checkbox_off.png) no-repeat;
    transition: background 0.15s;
    -webkit-transition: background 0.15s;
    vertical-align: middle;
}

input[type="checkbox"]:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    background: url(../images/checkbox_on.png) no-repeat;
}

.btn_box {
    margin: 90px 0;
    text-align: center;
}

button {
    width: 170px;
    height: 48px;
    box-sizing: border-box;
    font-family: "SCDream4";
    font-size: 17px;
    border-raidus: 0;
    margin-right: 10px;
    border: none;
    cursor: pointer;
}

button:last-child {
    marign-right: 0;
}

.btn_cancel {
    border: 1px solid #d5d5d5;
    color: #484848;
    background-color: #ffffff;
}

.btn_confirm {
    color: #ffffff;
    background-color: #00afec;
}

/*******footer*******/
footer {
    width: 100%;
    padding: 40px 0;
    background-color: #383838;
}

.footer_info {
    display: flex;
}

footer p {
    font-size: 12px;
    color: #d9d9d9;
    margin-left: 90px;
}
